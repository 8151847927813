import React,{ Suspense , lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

//页面
const List = lazy(() => import('./pages/list/index'));                  //客户列表
const Home = lazy(() => import('./pages/home/index'));                  //首页
const Login = lazy(() => import('./pages/login/index'));                //登录
const Report = lazy(() => import('./pages/report/index'));              //报告详情
const Operate = lazy(() => import('./pages/operate/index'));            //操作
const Card = lazy(() => import('./pages/card/index'));                  //卡管理
const Subscribe = lazy(() => import('./pages/subscribe/index'));        //预约
const Study = lazy(() => import('./pages/study/index'));                //预约
const Plan = lazy(() => import('./pages/plan/index'));                  //计划排班
const Liliao = lazy(() => import('./pages/liliao/index'));              //康复理疗
const Shuike = lazy(() => import('./pages/shuike/index'));              //睡眠床设置
const Interpret = lazy(() => import('./pages/interpret/index'));        //报告解读
const ReportList = lazy(() => import('./pages/reportList/index'));      //报告列表
const PlanList = lazy(() => import('./pages/planList/index'));          //报告列表
const PlanInfo = lazy(() => import('./pages/planInfo/index'));          //今日排班详情
const Shuimian = lazy(() => import('./pages/shuimian/index'));          //睡眠详情
const InfoRmation = lazy(() => import('./pages/information/index'));    //登记
const SleepShuimian = lazy(() => import('./pages/sleep_shuimian/index'));    //报告详情 - 睡眠设备
const SleepCode = lazy(() => import('./pages/code/index'));             //睡眠设备 - 关注公众号
const Yuyue = lazy(() => import('./pages/yuyue/index'));                //预约服务
const YuyueDetails = lazy(() => import('./pages/yuyueDetails/index'));                //预约服务详情

const N404 = lazy(() => import('./pages/404'));     //404


//路由组件
class Routes extends React.PureComponent {
    componentDidMount(){
        //计算
        // if(window.innerWidth>window.innerHeight){
        //     let html = document.getElementsByTagName('html')[0];
        //     html.style.fontSize = `${window.innerHeight*0.13}px`;
        // }
    }
    render(){
        return(
            <Router>
                <Suspense fallback={<div className="loading flex flex-x flex-y">
                        <div className="loading-min">
                            <img src='/images/loading.png'/>
                        </div>
                    </div>
                }>
                    <Switch>
                        <Route exact path="/" component={List}/>
                        <Route exact path="/diagnose" component={Home}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/card" component={Card}/>
                        <Route exact path="/report/:id" component={Report}/>
                        <Route exact path="/subscribe" component={Subscribe}/>
                        <Route exact path="/operate" component={Operate}/>
                        <Route exact path="/study" component={Study}/>
                        <Route exact path="/interpret" component={Interpret}/>
                        <Route exact path="/plan" component={Plan}/>
                        <Route exact path="/shuike" component={Shuike}/>
                        <Route exact path="/liliao" component={Liliao}/>
                        <Route exact path="/reportList" component={ReportList}/>
                        <Route exact path="/information" component={InfoRmation}/>
                        <Route exact path="/information/:id" component={InfoRmation}/>
                        <Route exact path="/planList" component={PlanList}/>
                        <Route exact path="/planInfo/:id" component={PlanInfo}/>
                        <Route exact path="/shuimian/:id" component={Shuimian}/>
                        <Route exact path="/sleeepShuimian/:id" component={SleepShuimian}/>
                        <Route exact path="/sleepCode" component={SleepCode}/>
                        <Route exact path="/yuyue" component={Yuyue}/>
                        <Route exact path="/yuyueDetails/:id" component={YuyueDetails}/>
                        <Route path="/*" component={N404}/>
                    </Switch>
                </Suspense>
            </Router>
        )
    }
}

export default Routes;