import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store/index'
//基础样式
import './style.less';
//路由
import Routes from './routes';

ReactDOM.render(
    <Provider store={store}>
        <Routes />
    </Provider>,
  document.getElementById('root')
);