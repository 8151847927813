import { LOGIN , LOGIN_VALUE , CODE } from './actionTypes';
import { message } from 'antd';

// 数据池
const defaultState = {
    code:'',            //企业编码
    user:'',            //账号
    pass:'',            //密码
    captcha_code:'1',   //验证码
    captcha_key:'1',    //验证码标识
    code_img:''         //验证码图片
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case LOGIN.POST_LOGIN_SUBMIT:     //登录 - 提交
            if(action.data.result.code===1){
                localStorage.setItem('token',action.data.result.data.token);
                let user = localStorage.user?JSON.parse(localStorage.user):[];
                let bool = true;
                user.map((use)=>{
                    if(use.code==newState.code){
                        bool=false;
                        use.user = newState.user
                    }
                })
                if(bool){
                    user.unshift({
                        code:newState.code,
                        user:newState.user
                    })
                }
                user = user.slice(0,5); 
                localStorage.setItem('user',JSON.stringify(user));
                //window.location.href='/';
                message.success(action.data.result.msg);
            }
            return newState;
        case CODE.POST_CODE_IMG:          //登录 - 验证码
            newState.captcha_key = action.data.captcha_key;
            newState.code_img = action.data.image;
            return newState;
        case LOGIN_VALUE:                 //登录 - 存储数据
            newState[action.params.key] = action.params.value;
            return newState;
        default:
            return state;
    }
};

export default Reducer;
