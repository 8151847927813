import { combineReducers } from 'redux';

import homeReducer from './home/reducer';
import loginReducer from './login/reducer';
import reportReducer from './report/reducer';
import listReducer from './list/reducer';
import operateReducer from './operate/reducer';
import subscribeReducer from './subscribe/reducer';
import liliaoReducer from './liliao/reducer';
import informationReducer from './information/reducer';
import yuyueReducer from './yuyue/reducer';

const Reducer = combineReducers({
    homeReducer,
    loginReducer,
    reportReducer,
    listReducer,
    operateReducer,
    subscribeReducer,
    liliaoReducer,
    informationReducer,
    yuyueReducer
});

export default Reducer;
  