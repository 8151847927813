import QRCode from 'qrcode';
//随机数
export function RandomString(length) {
    var str = '';
    for ( ; str.length < length; str += Math.random().toString(36).substr(2) );
    return str.substr(0, length);
}

//根据生日计算年龄
export function jsGetAge(strBirthday){
    let returnAge;
    let strBirthdayArr=strBirthday.split("-");  
    let birthYear = strBirthdayArr[0];  
    let birthMonth = strBirthdayArr[1];  
    let birthDay = strBirthdayArr[2]; 
    let d = new Date();  
    let nowYear = d.getFullYear();  
    let nowMonth = d.getMonth() + 1;  
    let nowDay = d.getDate();  
      
    if(nowYear.toString() === birthYear){  
        returnAge = 1;//同年 则为1岁  
    }  
    else{  
        var ageDiff = nowYear - birthYear ; //年之差  
        if(ageDiff > 0){  
            if(nowMonth === birthMonth){  
                var dayDiff = nowDay - birthDay;//日之差  
                if(dayDiff < 0){  
                    returnAge = ageDiff - 1;  
                }else{  
                    returnAge = ageDiff ;  
                }  
            }else{  
                var monthDiff = nowMonth - birthMonth;//月之差  
                if(monthDiff < 0){  
                    returnAge = ageDiff - 1;  
                }else{  
                    returnAge = ageDiff ;  
                }  
            }  
        }else{
            returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天  
        }  
    } 
    return returnAge;//返回周岁年龄
}

export let capi = ['一','二','三','四','五','六','七','八','九','十'];
export let take = [
    "口服",
    "嚼服",
    "含漱",
    "舌下含服",
    "外用",
    "滴鼻",
    "滴眼",
    "滴耳",
    "雾化吸入",
    "鼻腔喷入",
    "口腔喷入",
    "鼻腔冲洗",
    "注射",
    "皮下注射",
    "肌肉注射",
    "静脉注射",
    "穴位注射",
    "皮内注射",
    "腔内注射",
    "静脉滴注",
    "直肠滴注",
    "直肠给药",
    "阴道给药",
    "灌肠"
];

export let time = [
    "随时",
    "晨服",
    "餐前服",
    "餐中服",
    "餐后服",
    "睡前服"
];

export let mode =[
    "先煎",
    "后下",
    "包煎",
    "另煎",
    "打碎",
    "烊化",
    "泡服",
    "冲服",
    "煎汤代水",
    "包先煎",
    "包后下",
    "同煎",
    "炮",
    "打碎先煎",
    "煨",
    "外用"
];

export let gram = [
    "g",
    "kg",
    "个",
    "条",
    "根",
    "块",
    "支",
    "枚",
    "只",
    "对",
    "双",
    "袋",
    "朵",
    "片"
  ]

  export let box = [
    "盒",
    "袋",
    "瓶",
    "罐",
    "包",
    "件",
    "大盒",
    "板",
    "支",
    "提",
    "对",
    "条",
    "碗",
    "剂",
    "桶",
    "箱",
    "盆"
  ]
  export let unit = [
    "支",
    "片",
    "粒",
    "丸",
    "贴",
    "袋",
    "瓶",
    "枚",
    "吸",
    "滴",
    "包",
    "盒",
    "板",
    "个",
    "泡",
    "块",
    "张",
    "喷",
    "锭",
    "管",
    "卷",
    "挖",
    "只",
    "万单位",
    "人份",
    "套",
    "条",
    "格"
  ]

  //防抖函数
export let deBounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }
 
        timer = setTimeout(()=> {
            fn(...args);
        }, delay);
    }
}

//节流函数
export let throttle = (func, delay) => {
    let timer = null
    return function(...args){
        if (timer) return
        timer = setTimeout(() => {
            func.apply(this, args)
            timer = null
        }, delay)
    }
}

export let domain ='.saas.quanmai.cloud';

//获取二级域名前缀
export function getDomain(){
    let href =  window.location.href;
    if(href.indexOf("localhost")!=-1){
         return 'wx0588aef9780e1932';
    }else{
         return href.split(".")[0].split("//")[1];
    }
} 

//获取cookie
export function getCookie(name){
    var prefix = name + "="
    var start = document.cookie.indexOf(prefix)
    if (start == -1) {
        return null;
    }
    var end = document.cookie.indexOf(";", start + prefix.length)
    if (end == -1) {
        end = document.cookie.length;
    }
    var value = document.cookie.substring(start + prefix.length, end)
    return decodeURI(value);
}

//生成二维码

export function makecode(text){
    var imgurl = '';
    QRCode.toDataURL(text,
        {
            scale:7,
            margin:2,
            errorCorrectionLevel: 'H',
        },
        function (err, url) {
            imgurl = url;
        }
    );

    return imgurl;
}