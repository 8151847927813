//银行
import {createStore, applyMiddleware, compose} from 'redux'
import reducers from './reducers'

import createSagaMiddleware from 'redux-saga';
import mySages from './sagas';

//浏览器插件
const sagaMiddleware = createSagaMiddleware(mySages);
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

//添加中间件
const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware),
);

//创建
const store =  createStore(reducers, enhancer);

// 注意: 一定要放在最后
sagaMiddleware.run(mySages);

export default  store;