const  echarts = require('echarts');
require('echarts/lib/chart/gauge');
require('echarts/lib/component/toolbox');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');
require('echarts/lib/chart/bar');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/component/title');
require('echarts/lib/chart/radar');


//仪表盘 obj { min:0,max:9,data:3 }
export function yibiao(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;
    option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: obj.min,
            max: obj.max,
            splitNumber: 1,
            itemStyle: {
              color: 'rgb(23,159,129)'
            },
            progress: {
              show: true,
              width: 10
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 20
              }
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            splitLine: {
              distance: -52,
              length: 14,
              lineStyle: {
                width: 3,
                color: '#999'
              }
            },
            axisLabel: {
              distance: -20,
              color: '#999',
              fontSize: 20
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '50%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '10%'],
              fontSize: window.innerWidth>window.innerHeight?28:16,
              formatter: obj.value,
              color: 'rgb(23,159,129)'
            },
            data: [
              {
                value: obj.data
              }
            ]
          },
          {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: obj.min,
            max: obj.max,
            itemStyle: {
              color: 'rgb(23,159,129)'
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
          }
        ]
      };

      option && myChart.setOption(option);
}

//睡前
export  function shuiqian(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;
    option = {
        color:['rgb(23,159,129)'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter:function(params){
                let state = ''
                switch(params[0].value){
                    case 0:
                        state = window.innerWidth>window.innerHeight?'睡前/醒后在床':'在床';
                        break;
                    case 1:
                        state = '觉醒';
                        break;
                    case 2:
                        state = '浅睡';
                        break;
                    case 3:
                        state = 'REM';
                        break;
                    case 4:
                        state = '深睡';
                        break;
                }
                return  params[0].name + '<br/>' + `${params[0].seriesName}：${state}`;
            }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: obj.xAxis
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value){
                switch(value){
                    case 0:
                        return window.innerWidth>window.innerHeight?'睡前/醒后在床':'在床';
                    case 1:
                        return '觉醒';
                    case 2:
                        return '浅睡';
                    case 3:
                        return 'REM';
                    case 4:
                        return '深睡';
                }
            }
          }
        },
        series: [
          {
            name: '睡眠状态',
            type: 'line',
            step: 'start',
            data: obj.data,
            lineStyle:{
                color:'rgb(23,159,129)'
            }
          }
        ]
      };
      option && myChart.setOption(option);      
}

// K线图
export function Kline(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;
    option = {
        color:['rgb(23,159,129)'],
        xAxis: {
          type: 'category',
          data: obj.data
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: obj.value,
            type: 'line',
            smooth: true,
            lineStyle:{
                color:'rgb(23,159,129)'
            }
          }
        ]
      };
    option && myChart.setOption(option);
}

//柱状图
export function pause(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;
    option = {
        color:['rgb(23,159,129)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter: function (params) {
              if(id=='diagram-6'){
                let label = `${params[0].name}<br/>`;
                params.map((param)=>{
                      if(param.componentIndex==0){
                        label += `觉醒次数：${param.data}<br/>`;
                      }else{
                        label += `离床次数：${param.data}<br/>`;
                      }
                });
                return label
              }else{
                return false
              }
            }
        },
        xAxis: {
          type: 'category',
          data: obj.data
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: obj.value,
            type: 'bar',
            itemStyle:{
                color:'rgb(23,159,129)'
            },
            // showBackground: true,
            // backgroundStyle: {
            //   color: 'rgba(180, 180, 180, 0.2)'
            // }
          }
        ]
    };
    if(obj.value1){
      option.series.push({
        data: obj.value1,
        type: 'bar',
        itemStyle:{
            color:'rgb(147,190,71)'
        },
      })
    }
    if(id=='diagram-6'){
      option.yAxis.interval = 1;    //强制设置坐标轴分割间隔。
      option.yAxis.max = 2;
    }
    option && myChart.setOption(option);
}

//面积图
export function area(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;
    option = {
        color:['rgb(23,159,129)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: obj.data
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: obj.value,
            type: 'line',
            areaStyle: {
                color:'rgb(23,159,129)'
            },
            lineStyle:{
                color:'rgb(23,159,129)'
            }
          }
        ]
    };
    option && myChart.setOption(option);
}

//雷达图
export function radar(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;

    option = {
        color:['rgb(23,159,129)'],
        radar: {
          // shape: 'circle',
          indicator: obj.indicator,          
          axisName:{
            fontSize:window.innerWidth>window.innerHeight?20:10,
            color:'#000'
          }
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: obj.value,
                areaStyle: {
                    color: 'rgba(23,159,129, 0.6)'
                },
                // label:{
                //     show: true,
                //     formatter: function (params) {
                //         return obj.indicator[params.dimensionIndex].score!==0?obj.indicator[params.dimensionIndex].label:'';
                //     }
                // }
              }
            ]
          }
        ]
      };

    option && myChart.setOption(option);
}

//瀑布图
export function waterfall(id,obj){
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    var option;

    option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let label = `${params[0].name}<br/>`;
            let obj = {};
            params.map((param)=>{
                if(param.seriesName!=='起床时间'){
                    obj[param.seriesName] = `${param.value?getTime(param.value):0}`;
                }
            });
            label += `入睡时间：${obj['入睡时间']}<br/>睡眠时间：${obj['睡眠时间']}<br/>觉醒时间：${obj['觉醒时间']}`;
            return label
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          data: obj.data
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            formatter:function(params){
                return fanDate(params)
            }
          }
          
        },
        series: [
          {
            name: '起床时间',
            type: 'bar',
            stack: 'Ad',
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
              }
            },
            data: obj.qic
          },
          {
            name: '觉醒时间',
            type: 'bar',
            stack: 'Ad',
            data: obj.juex,
            itemStyle: {
                color: "rgb(153,255,242)"
            }
          },
          {
            name: '睡眠时间',
            type: 'bar',
            stack: 'Ad',
            data: obj.shuim,
            itemStyle: {
                color: "rgb(22,158,129)"
            }
          },
          {
            name: '入睡时间',
            type: 'bar',
            stack: 'Ad',
            data: obj.shangc,
            itemStyle: {
                color: "rgb(153,255,242)"
            }
          }
        ]
      };

    option && myChart.setOption(option);
}

//反计算 算回时间
function fanDate(date4){
    let n_date = Date.parse(`2023/1/1 12:00:00`);
    n_date = new Date(n_date + (86400-date4)*1000);
    return `${max10(n_date.getHours())}:${max10(n_date.getMinutes())}`
}

function max10(date5){
    return date5>=10?date5:`0${date5}`;
}

function getTime(time) {
    // 转换为式分秒
    let h = parseInt(time / 60 / 60 % 24);
    let m = parseInt(time / 60 % 60);
    // 作为返回值返回
    return `${h?`${h}小时`:''}${m?`${m}分钟`:''}`
}