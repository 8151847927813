//设备详情
export const SHUIKE_DESCRIPTION = {
    SHUIKE_DESCRIPTION:'SHUIKE_DESCRIPTION',
    GET_SHUIKE_DESCRIPTION:'GET_DESCRIPTION'
};

//根据机构来获取房间号列表
export const SHUIKE_ORGANIZATION_HOME = {
    SHUIKE_ORGANIZATION_HOME:'SHUIKE_ORGANIZATION_HOME',
    GET_SHUIKE_ORGANIZATION_HOME:'GET_SHUIKE_ORGANIZATION_HOME'
}

//查询用户基本信息(用户和房间绑定)
export const BASE_INFO_FIND = {
    BASE_INFO_FIND:'BASE_INFO_FIND',
    POST_BASE_INFO_FIND:'POST_BASE_INFO_FIND'
};

//用户基本信息填写及修改(用户和房间绑定)
export const SHUIKE_BASE_INFO_ADD = {
    SHUIKE_BASE_INFO_ADD:'SHUIKE_BASE_INFO_ADD',
    POST_SHUIKE_BASE_INFO_ADD:'POST_SHUIKE_BASE_INFO_ADD'
};

//获取报告详情
export const SHUIKE_BASE_REPORT = {
    SHUIKE_BASE_REPORT:'SHUIKE_BASE_REPORT',
    GET_SHUIKE_BASE_REPORT:'GET_SHUIKE_BASE_REPORT'
};

//修改 状态
export const SHUIKE_MODIFY = "SHUIKE_MODIFY";