import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import { YUYUE } from "./actionTypes";

// 预约服务的接口
export function* postYuYue(active) {
    const result = yield axios(`/kfApi/${active.params.url}`,active.params.body,active.params.type);
    if(result.code===1){
        const data = result.data;
        if(active.params.fun){
            active.params.fun();
        }
        yield put({
            type: YUYUE.POST_YUYUE,
            key:active.params.key,
            data
        }) 
    }
}