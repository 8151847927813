//登录
export const admin_login = "admin/login";
//验证码
export const code_img = "captcha";
//用户列表
export const users = "new_customer";
//病名列表
export const disease_name = "config/zzbkf_disease_name";
//根据主诉和病名找症状（单选）
export const get_symptoms = "zzbkf/get_symptoms";
//根据单个症状id获取辨别症状列表（多选）
export const get_distinguish_symptoms = "zzbkf/get_distinguish_symptoms";
//提交报告
export const submit = "zzbkf/submit";
//报告详情
export const report_info = "ptkf/info";
//完诊
export const final = "zzbkf/final";
//就诊记录
export const list = "ptkf/list";
//新建用户
export const new_user = "new_user";
//相关搜索
export const search = "config/search";
//相关搜索
export const symptoms = "zzbkf/add_symptoms";
//获取所有科室
export const keshi = "ptkf/get_keshi";
//根据科室id获取所有的病名列表(单选)
export const disease = "ptkf/get_disease";
//根据病名获取所有的症状列表(多选)
export const liliao_symptoms = "ptkf/get_symptoms";
//理疗提交报告
export const liliao_submit = "ptkf/submit";

//会员列表
export const liliao_vip = "admin/user/user_list";
//操作员列表
//export const user_operator = "user/operator";
export const user_operator = "user/cudanyuan_list";
//销售(或购买)订单列表
export const sale_list = "admin/order/list";
//销售记录详情(获取订单具体详情)
export const sale_list_info = "admin/order/get_desc_list";
//单卡基本详情
export const card_info = "admin/card/info";
//卡列表
export const card_list = "admin/card/list";
//卡激活
export const card_active = "admin/card/active";
//撤销激活
export const cancel_active = "admin/card/cancel_active";
//卡销售
export const company_sell_code = "admin/card/company_sell";
//服务操作记录(明细)列表
export const card_operate = "admin/set_meal/operate_list";
//服务套餐列表
export const meal_list = "admin/set_meal/list";
//获取系统套餐详情及服务列表
export const meal_info = "admin/set_meal/info";
//销售套餐(租户端)
//export const company_sell = "admin/set_meal/company_sell";
export const company_sell = "admin/set_meal/company_sell_with_count";
//确认支付
export const order_confirm = "admin/order/confirm_pay";
// 根据患者id来获取用户套餐列表
export const user_set_meal = "admin/set_meal/user_set_meal";
// 获取患者套餐内的服务列表
export const user_service = "admin/set_meal/user_service";
// 患者套餐绑定专项理疗(病名)
export const bind_disease = "admin/set_meal/bind_disease";
// 操作患者套餐内的服务
export const user_service_operate = "admin/set_meal/user_service_operate";
// 获取系统字典列表
export const dictionaries = "dictionaries";
// 卡销售或套餐取消订单(租户端)
export const meal_cancel = "admin/order/cancel";
// 卡销售或套餐确定支付(租户端)
export const confirm_pay = "admin/order/confirm_pay";
// 获取支付图片
export const pay_images = "admin/pay_images";
// 租户端用户预约的列表
export const subscribe_list = "admin/subscribe/list";
// 取消预约
export const subscribe_cancel = "admin/subscribe/cancel";
// 更新/修改预约
export const subscribe_update = "admin/subscribe/update";
//门店列表
export const store = "admin/subscribe/organization";
//获取服务项目列表
export const service_list = "admin/service/list";
//获取账户信息
export const info = "admin/account/info";
//上传oss
export const oss = "report/oss";
//预约时间列表
export const time_list = "admin/subscribe/time_list";
//天际预约时间
export const time_add = "admin/subscribe/time_add";

//当天需要做的服务剩余量
export const plan_remaining = "admin/service_plan/remaining";
//获取公司所有专项服务项目列表
export const plan_service_list = "admin/service_plan/service_list";
//健康管理师列表
export const health_manager_list = "user/health_manager_list";
//服务项目列表
export const service_plan_day_list = "admin/service_plan/service_plan_day_list";
//操作服务项目
export const plan_operate = "admin/service_plan/operate";
//添加计划(专项)
export const plan_add = "admin/service_plan/add";
//理疗服务专项列表
export const plan_list = "admin/service_plan/list";
//理疗服务专项结束
export const plan_over = "admin/service_plan/over";
//理疗服务专项取消
export const plan_cancel = "admin/service_plan/cancel";
//理疗服务专项详情
export const plan_info = "admin/service_plan/info";
//理疗服务取消排班
export const plan_cancel_time = "admin/service_plan/cancel_time";
//排班日期选择
export const plan_change_time = "admin/service_plan/change_time";
//时间段列表
export const time_list_time = "admin/plan/paiban_time_list";
//获取患者在本次专项服务期间最近的一次理疗报告详情
export const last_info = "ptkf/last_info";
//根据立创报告获取日期列表
export const lichuang_date = "third_party/lichuang_date";
//鹰演报告详情
export const yingyan_report = "third_party/yingyan_report";
//立创接口·
export const lichuang_report = "third_party/lichuang_report";
//我方报告详情
export const self_report = "third_party/self_report";
//销售套餐退款
export const company_tuikuan = "admin/set_meal/company_tuikuan";
//token 获取账户信息
export const jwt_auth = "user-info";
//获取日期列表(单个的)
export const third_party_date = "third_party/date";
//三方报告汇总
export const third_party_count = "third_party/count";
//脉全报告详情
export const maiquan = "third_party/maiquan_report";
//获取某会员当日所有未完成的排班
export const control_list = "admin/service_plan/current";
//客户信息
export const user_info = "admin/user/user_info";
//未排班
export const no_paibanuser_list = "admin/user/no_paibanuser_list";
//健康检测指标报告
export const zhibiao_report = "third_party/zhibiao_report";
//健康检测指标报告创建
export const zhibiao_report_create = "third_party/zhibiao_report_create";
//健康问券报告
export const wenquan_report = "third_party/wenquan_report";
//健康问券创建或修改
export const wenquan_report_edit = "third_party/wenquan_report_edit";
//中医调养方法列表
export const zhongyi_tiaoyang_list = "third_party/zhongyi_tiaoyang_list";
//中医调养方法创建或修改
export const zhongyi_tiaoyang_edit = "third_party/zhongyi_tiaoyang_edit"; 
//功能医学干预列表
export const gongneng_ganyu_list = "third_party/gongneng_ganyu_list"; 
//功能医学干预创建或修改
export const gongneng_ganyu_edit = "third_party/gongneng_ganyu_edit"; 
//营养补充列表
export const yingyang_list = "third_party/yingyang_list";
//健康评估及健康管理方案报告
export const fangan_report = "third_party/fangan_report"; 
//健康评估及健康管理方案创建或修改
export const fangan_report_edit = "third_party/fangan_report_edit"; 
//根据用户id获取睡客服务计划列表
export const shuike = "admin/service_plan/shuike"; 
//根据睡眠日期获取床垫列表
export const shuike_list = "admin/service_plan/paiban_shuike_list";
//睡客排班日期、房间选择
export const shuike_room = "admin/service_plan/shuike_change_room";
//睡客报告
export const shuike_report = "third_party/shuike_report";
//睡客报告（无token）
export const shuike_no_report = "third_party/not_token/shuike_report";

//睡客报告（睡眠设备）
export const shuike_sleep_report = "sleep/equipment/admin_report";

//设备详情
export const shuike_description = "sleep/equipment/description";
//根据机构来获取房间号列表
export const shuike_organization_home = "sleep/equipment/organization_home";
//查询用户基本信息(用户和房间绑定)
export const shuike_base_info_find = "sleep/equipment/base_info_find";
//用户基本信息填写及修改(用户和房间绑定)
export const shuike_base_info_add = "sleep/equipment/base_info_add";
//获取报告详情
export const shuike_base_report = "sleep/equipment/report";


//预约列表
export const yuyue_list = `fuwu/wx/yuyue_list`;
//判断是否是业务员、非业务员、普通用户
export const role_type = `fuwu/wx/role_type`;
//批量申请预约
export const yuyue_shenqing_list = `fuwu/yuyue_shenqing_list`;
//申请预约
export const yuyue_shenqing = `fuwu/yuyue_shenqing`;
//确认操作
export const yuyue_queren = `fuwu/yuyue_queren`;
//取消预约
export const yuyue_quxiao = `fuwu/yuyue_quxiao`;
//服务点列表
export const address_list = `fuwu/address_list`;


