//请求
import { takeEvery } from 'redux-saga/effects'
//诊断
import { USER_LIST , DISEASE , SYMPTOM, DISTINGUISH , SUBMIT , PATIENT , VISIT , DIV , KESHI, LILIAO_DISEASE, LILIAO_SYMPTOMS, LILIAO_SUBMIT , INFOS , CLICKID , NO_PAIBANUSER_LIST
 } from "./home/actionTypes";
import { getUserList , getDiseaseName , getSymptom , getDistinguish , getSubmit , getAddUser , getVisit , getDivSubmit , getKeshi , getLiliaoDisease , getLiliaoSymptoms , getLiliaoSubmit , getInfo , getUserInfo , getNoPaibanuserList } from "./home/sagas";
//登录
import { LOGIN , CODE } from "./login/actionTypes";
import { postSubmit , getCode } from "./login/sagas";
//报告
import { REPORT , COMPLETE , SEARCH } from "./report/actionTypes";
import { getReport , getComplete , getSearch } from "./report/sagas";
//客户列表
import { VIP_LIST, OPERATOR_LIST, SALE_LIST, SALE_INFO, CARD_INFO , CARD_LIST , CARD_ACTIVE , MEAL_LIST , MEAL_INFO , COMPANY_SELL , CARD_OPERATE , ORDER_CONFIRM , PAY_IMAGE , COMPANY_SELL_CODE , CANCEL_ACTIVE , COMPANY_TUIKUAN } from "./list/actionTypes";
import { postVipList , getOperatorList , postSaleList , postSaleInfo , postCardInfo , getCardList , postCardActive , postMealList , postMealInfo , postCompanySell , postCardOperate , getOrderConfirm , getPayImage , postCompanySellCode , postCancelActive , postCompanyTuikuan } from "./list/sagas";
//操作
import { USER_MEAL , USER_SERVICE , BIND_DISEASE , DICTIONARIES , SERVICE_OPERATE ,MEAL_DEFINE, MEAL_REVOKE , SERVICE_LIST , OSS } from "./operate/actionTypes";
import { getUserMealList , postUserServiceList , getBindDiseaseList , getDictionariesList , getServiceOperate , getMealDefine , getMealRevoke , getServiceList , getOss , getOssImg } from "./operate/sagas";
//预约
import { SUBSCRIBE , SUBSCRIBE_CANCEL , SUBSCRIBE_UPDATE , STORE , TIMEADD, TIMELIST } from "./subscribe/actionTypes";
import { postSubscribeList , getSubscribeCancel , postSubscribeUpdate , getStoreList , postTimeList , postTimeAdd } from "./subscribe/sagas";
//康养理疗
import { REMAINING, PLAN_SERVICE_LIST, HEALTH_MANAGER_LIST, SERVICE_PLAN_DAY_LIST, SERVICE_PLAN_OPERATE , SERVICE_PLAN_ADD, SERVICE_PLAN_LIST,  SERVICE_PLAN_OVER,  SERVICE_PLAN_CANCEL,  SERVICE_PLAN_INFO,  SERVICE_PLAN_CANCEL_TIME,  SERVICE_PLAN_CHANGE_TIME , TIME_LIST_TIME , LAST_INFO , LICHUANG_REPORT , YINGYAN_REPORT , SEF_REPORT , LICHUANG_DATE , JWT_AUTH , THIR_PARTY, THIR_PARTY_COUNT , CONTRO_LIST , MAIQUAN_REPORT , ZHIBIAO_REPORT, ZHIBIAO_REPORT_CREATE, WENQUAN_REPORT, WENQUAN_REPORT_EDIT, ZHONGYI_TIAOYANG_LIST, ZHONGYI_TIAOYANG_EDIT, GONGNENG_GANYU_LIST, GONGNENG_GANYU_EDIT, FANGAN_REPORT, FANGAN_REPORT_EDIT , YINGYANG_LIST , SHUIKE , SHUIKE_LIST , SHUIKE_ROOM , SHUIKE_REPORT } from "./liliao/actionTypes";
import { getRemaining , getPlanServiceList , getHealthManagerList , postServicePlanDayList , getServicePlanOperate , postServicePlanAdd , grtServicePlanList , grtServicePlanOver , grtServicePlanCancel , grtServicePlanInfo , grtServicePlanCancelTime , postServicePlanChangeTime , getTimeListTime , getLastInfo , getLichuangReport , getYingyanReport , getQuanmaiReport , getLichuangDate  , postJwtAuth , getThirParty , getThirPartyCount  , getControlList , getMaiQuanReport , getZhibiaoReport , postZhibiaoReportCreate , getWenquanReport , postWenquanReportEdit , getZhongyiTiaoyangList , postZhongyiTiaoyangEdit , getGongnengGanyuList , postGongnengGanyuEdit , getFanganReport , postFanganReportEdit , getYingyangList , getShuike , getShuikeList , postShuikeRoom , getShuikeReport } from "./liliao/sagas";
//设备报告
import { SHUIKE_DESCRIPTION, SHUIKE_ORGANIZATION_HOME, BASE_INFO_FIND, SHUIKE_BASE_INFO_ADD, SHUIKE_BASE_REPORT } from "./information/actionTypes";
import { baseReport , shuikeDescription , shuikeOrganizationHome , baseInfoFind , baseInfoAdd } from "./information/sagas";
//预约服务
import { YUYUE } from "./yuyue/actionTypes";
import { postYuYue } from "./yuyue/sagas";

function* mySaga() {
    // 接受action传递的任务
    yield takeEvery(USER_LIST.LIST, getUserList);
    yield takeEvery(LOGIN.LOGIN_SUBMIT, postSubmit);
    yield takeEvery(LOGIN.POST_LOGIN_SUBMIT, getInfo );
    yield takeEvery(CODE.CODE_IMG, getCode);
    yield takeEvery(DISEASE.DISEASE_NAME, getDiseaseName);
    yield takeEvery(SYMPTOM.SYMPTOM_LOOK, getSymptom);
    yield takeEvery(DISTINGUISH.DISTINGUISH_LOOK, getDistinguish);
    yield takeEvery(SUBMIT.SUBMIT_REPORT, getSubmit);
    yield takeEvery(REPORT.REPORT_INFO, getReport);
    yield takeEvery(COMPLETE.COMPLETE_REPORT, getComplete);
    yield takeEvery(PATIENT.PATIENT_USER, getAddUser);
    yield takeEvery(SEARCH.SEARCH_ING, getSearch);
    yield takeEvery(VISIT.VISIT_RECORD, getVisit);
    yield takeEvery(DIV.DIV_SUBMIT, getDivSubmit);
    yield takeEvery(DIV.POST_DIV_SUBMIT, getSubmit);
    yield takeEvery(KESHI.KESHI_DATA, getKeshi);
    yield takeEvery(LILIAO_DISEASE.DISEASE_DATA, getLiliaoDisease);
    yield takeEvery(LILIAO_SYMPTOMS.SYMPTOMS_DATA, getLiliaoSymptoms);
    yield takeEvery(LILIAO_SUBMIT.SUBMIT, getLiliaoSubmit);
    yield takeEvery(KESHI.GET_KESHI_DATA, getLiliaoDisease);
    yield takeEvery(VIP_LIST.LIST, postVipList);
    yield takeEvery(OPERATOR_LIST.LIST, getOperatorList);
    yield takeEvery(SALE_LIST.LIST, postSaleList);
    yield takeEvery(SALE_INFO.INFO, postSaleInfo);
    yield takeEvery(CARD_INFO.INFO, postCardInfo);
    yield takeEvery(CARD_LIST.LIST, getCardList);
    yield takeEvery(CARD_ACTIVE.ACTIVE, postCardActive);
    yield takeEvery(MEAL_LIST.LIST, postMealList);
    yield takeEvery(MEAL_INFO.INFO, postMealInfo);
    yield takeEvery(COMPANY_SELL.SELL, postCompanySell);
    yield takeEvery(CARD_OPERATE.OPERATE, postCardOperate);
    yield takeEvery(USER_MEAL.MEAL, getUserMealList);
    yield takeEvery(USER_SERVICE.SERVICE, postUserServiceList);
    yield takeEvery(BIND_DISEASE.DISEASE, getBindDiseaseList);
    yield takeEvery(ORDER_CONFIRM.CONFIRM, getOrderConfirm);
    yield takeEvery(BIND_DISEASE.GET_DISEASE, postUserServiceList);
    yield takeEvery(DICTIONARIES.DICTIONARIES, getDictionariesList);
    yield takeEvery(SERVICE_OPERATE.OPERATE, getServiceOperate);
    yield takeEvery(SERVICE_OPERATE.REPORT, getReport);
    yield takeEvery(MEAL_DEFINE.DEFINE, getMealDefine);
    yield takeEvery(MEAL_REVOKE.REVOKE, getMealRevoke);
    yield takeEvery(PAY_IMAGE.IMAGE, getPayImage);
    yield takeEvery(SUBSCRIBE.SUBSCRIBE_LIST, postSubscribeList);
    yield takeEvery(SUBSCRIBE_CANCEL.SUBSCRIBE_CANCEL, getSubscribeCancel);
    yield takeEvery(SUBSCRIBE_UPDATE.SUBSCRIBE_UPDATE, postSubscribeUpdate);
    yield takeEvery(STORE.STORE_LIST, getStoreList);
    yield takeEvery(SUBSCRIBE_CANCEL.GET_SUBSCRIBE_CANCEL, postSubscribeList);
    yield takeEvery(SUBSCRIBE_UPDATE.POST_SUBSCRIBE_UPDATE, postSubscribeList);
    yield takeEvery(SERVICE_LIST.SERVICE_LIST, getServiceList);
    yield takeEvery(COMPANY_SELL_CODE.CODE, postCompanySellCode);
    yield takeEvery(INFOS.INFO, getInfo);
    yield takeEvery(CANCEL_ACTIVE.ACTIVE, postCancelActive);
    yield takeEvery(OSS.IMG, getOss);
    yield takeEvery(OSS.GET_IMG, getOssImg);
    yield takeEvery(TIMEADD.TIME_ADD, postTimeAdd);
    yield takeEvery(TIMELIST.TIME_LIST, postTimeList);
    yield takeEvery(REMAINING.REMAINING, getRemaining);
    yield takeEvery(PLAN_SERVICE_LIST.PLAN_SERVICE_LIST, getPlanServiceList);
    yield takeEvery(PLAN_SERVICE_LIST.JIN_R_LIST, postServicePlanDayList);
    yield takeEvery(HEALTH_MANAGER_LIST.HEALTH_MANAGER_LIST, getHealthManagerList);
    yield takeEvery(SERVICE_PLAN_DAY_LIST.SERVICE_PLAN_DAY_LIST, postServicePlanDayList);
    yield takeEvery(SERVICE_PLAN_OPERATE.SERVICE_PLAN_OPERATE, getServicePlanOperate);
    yield takeEvery(SERVICE_PLAN_ADD.SERVICE_PLAN_ADD, postServicePlanAdd);
    yield takeEvery(SERVICE_PLAN_ADD.POST_SERVICE_PLAN_ADD, grtServicePlanList);
    yield takeEvery(SERVICE_PLAN_ADD.POST_SERVICE_PLAN_ADD, postCardInfo);
    yield takeEvery(SERVICE_PLAN_LIST.SERVICE_PLAN_LIST, grtServicePlanList);
    yield takeEvery(SERVICE_PLAN_OVER.SERVICE_PLAN_OVER, grtServicePlanOver);
    yield takeEvery(SERVICE_PLAN_OVER.GET_SERVICE_PLAN_OVER, grtServicePlanList);
    yield takeEvery(SERVICE_PLAN_CANCEL.SERVICE_PLAN_CANCEL, grtServicePlanCancel);
    yield takeEvery(SERVICE_PLAN_CANCEL.GET_SERVICE_PLAN_CANCEL, grtServicePlanList);
    yield takeEvery(SERVICE_PLAN_CANCEL.GET_SERVICE_PLAN_CANCEL, postCardInfo);
    yield takeEvery(SERVICE_PLAN_INFO.SERVICE_PLAN_INFO, grtServicePlanInfo);
    yield takeEvery(SERVICE_PLAN_CANCEL_TIME.SERVICE_PLAN_CANCEL_TIME, grtServicePlanCancelTime);
    yield takeEvery(SERVICE_PLAN_CANCEL_TIME.GET_SERVICE_PLAN_CANCEL_TIME, grtServicePlanInfo);
    yield takeEvery(SERVICE_PLAN_CHANGE_TIME.SERVICE_PLAN_CHANGE_TIME, postServicePlanChangeTime);
    yield takeEvery(SERVICE_PLAN_CHANGE_TIME.POST_SERVICE_PLAN_CHANGE_TIME, grtServicePlanInfo);
    yield takeEvery(TIME_LIST_TIME.TIME_LIST_TIME, getTimeListTime);
    yield takeEvery(LAST_INFO.LAST_INFO, getLastInfo);
    yield takeEvery(YINGYAN_REPORT.YINGYAN_REPORT, getYingyanReport);
    yield takeEvery(LICHUANG_REPORT.LICHUANG_REPORT, getLichuangReport);
    yield takeEvery(SEF_REPORT.SEF_REPORT, getQuanmaiReport);
    yield takeEvery(LICHUANG_DATE.LICHUANG_DATE, getLichuangDate);
    yield takeEvery(LICHUANG_DATE.GET_LICHUANG_DATE, getQuanmaiReport);
    yield takeEvery(LICHUANG_DATE.GET_LICHUANG_DATE, getYingyanReport);
    yield takeEvery(LICHUANG_DATE.GET_LICHUANG_DATE, getLichuangReport);
    yield takeEvery(COMPANY_TUIKUAN.COMPANY_TUIKUAN, postCompanyTuikuan);
    yield takeEvery(JWT_AUTH.JWT_AUTH, postJwtAuth);
    yield takeEvery(JWT_AUTH.POST_JWT_AUTH, getThirPartyCount);
    yield takeEvery(JWT_AUTH.JWT_PLAN, grtServicePlanList);
    yield takeEvery(JWT_AUTH.JWT_CURRENT, getControlList);
    yield takeEvery(THIR_PARTY.THIR_PARTY, getThirParty);
    yield takeEvery(THIR_PARTY.THIR_PARTY_YINGYAN, getYingyanReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_LICHUANG, getLichuangReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_MAIQUAN, getMaiQuanReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_WENQUAN, getWenquanReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_ZHIBIAO, getZhibiaoReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_FANGAN, getFanganReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_SELF, getQuanmaiReport);
    yield takeEvery(THIR_PARTY.THIR_PARTY_SHUIKE, getShuikeReport );
    yield takeEvery(THIR_PARTY_COUNT.THIR_PARTY_COUNT, getThirPartyCount);
    yield takeEvery(CONTRO_LIST.CONTRO_LIST, getControlList);
    yield takeEvery(MAIQUAN_REPORT.MAIQUAN_REPORT, getMaiQuanReport);
    yield takeEvery(CLICKID.CLICKID, getUserInfo);
    yield takeEvery(NO_PAIBANUSER_LIST.NO_PAIBANUSER_LIST, getNoPaibanuserList);
    yield takeEvery(ZHIBIAO_REPORT.ZHIBIAO_REPORT, getZhibiaoReport);
    yield takeEvery(ZHIBIAO_REPORT_CREATE.ZHIBIAO_REPORT_CREATE, postZhibiaoReportCreate);
    yield takeEvery(WENQUAN_REPORT.WENQUAN_REPORT, getWenquanReport);
    yield takeEvery(WENQUAN_REPORT_EDIT.WENQUAN_REPORT_EDIT, postWenquanReportEdit);
    yield takeEvery(ZHONGYI_TIAOYANG_LIST.ZHONGYI_TIAOYANG_LIST, getZhongyiTiaoyangList);
    yield takeEvery(ZHONGYI_TIAOYANG_EDIT.ZHONGYI_TIAOYANG_EDIT, postZhongyiTiaoyangEdit);
    yield takeEvery(GONGNENG_GANYU_LIST.GONGNENG_GANYU_LIST, getGongnengGanyuList);
    yield takeEvery(GONGNENG_GANYU_EDIT.GONGNENG_GANYU_EDIT, postGongnengGanyuEdit);
    yield takeEvery(FANGAN_REPORT.FANGAN_REPORT, getFanganReport);
    yield takeEvery(FANGAN_REPORT_EDIT.FANGAN_REPORT_EDIT, postFanganReportEdit);
    yield takeEvery(YINGYANG_LIST.YINGYANG_LIST, getYingyangList);
    yield takeEvery(SHUIKE.SHUIKE, getShuike);
    yield takeEvery(SHUIKE_LIST.SHUIKE_LIST, getShuikeList);
    yield takeEvery(SHUIKE_ROOM.SHUIKE_ROOM, postShuikeRoom);
    yield takeEvery(SHUIKE_ROOM.POST_SHUIKE_ROOM, getShuike);
    yield takeEvery(SERVICE_PLAN_CANCEL_TIME.GET_CANCEL_SHUIKE, getShuike);
    yield takeEvery(SHUIKE_REPORT.SHUIKE_REPORT, getShuikeReport);
    yield takeEvery(SHUIKE_DESCRIPTION.SHUIKE_DESCRIPTION, shuikeDescription);
    yield takeEvery(SHUIKE_ORGANIZATION_HOME.SHUIKE_ORGANIZATION_HOME, shuikeOrganizationHome);
    yield takeEvery(BASE_INFO_FIND.BASE_INFO_FIND, baseInfoFind);
    yield takeEvery(SHUIKE_BASE_INFO_ADD.SHUIKE_BASE_INFO_ADD, baseInfoAdd);
    yield takeEvery(SHUIKE_BASE_REPORT.SHUIKE_BASE_REPORT, baseReport);
    yield takeEvery(SHUIKE_DESCRIPTION.GET_SHUIKE_DESCRIPTION, baseInfoFind);
    yield takeEvery(YUYUE.YUYUE, postYuYue);
}

export default mySaga;