import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import {
    REMAINING,
    PLAN_SERVICE_LIST,
    HEALTH_MANAGER_LIST,
    SERVICE_PLAN_DAY_LIST,
    SERVICE_PLAN_OPERATE,
    SERVICE_PLAN_ADD,
    SERVICE_PLAN_LIST,
    SERVICE_PLAN_OVER,
    SERVICE_PLAN_CANCEL,
    SERVICE_PLAN_INFO,
    SERVICE_PLAN_CANCEL_TIME,
    SERVICE_PLAN_CHANGE_TIME,
    TIME_LIST_TIME,
    LAST_INFO,
    LICHUANG_REPORT,
    YINGYAN_REPORT,
    SEF_REPORT,
    LICHUANG_DATE,
    JWT_AUTH,
    THIR_PARTY,
    THIR_PARTY_COUNT,
    CONTRO_LIST,
    MAIQUAN_REPORT,
    ZHIBIAO_REPORT,
    ZHIBIAO_REPORT_CREATE,
    WENQUAN_REPORT,
    WENQUAN_REPORT_EDIT,
    ZHONGYI_TIAOYANG_LIST,
    ZHONGYI_TIAOYANG_EDIT,
    GONGNENG_GANYU_LIST,
    GONGNENG_GANYU_EDIT,
    FANGAN_REPORT,
    FANGAN_REPORT_EDIT,
    YINGYANG_LIST,
    SHUIKE,
    SHUIKE_LIST,
    SHUIKE_ROOM,
    SHUIKE_REPORT
} from './actionTypes'
import { plan_remaining , plan_service_list , health_manager_list , service_plan_day_list , plan_operate , plan_add , plan_list , plan_over, plan_cancel , plan_info , plan_cancel_time , plan_change_time , time_list_time , last_info , lichuang_report , yingyan_report , self_report , lichuang_date , jwt_auth , third_party_date , third_party_count , control_list , maiquan , zhibiao_report , zhibiao_report_create , wenquan_report , wenquan_report_edit , zhongyi_tiaoyang_list , zhongyi_tiaoyang_edit , gongneng_ganyu_list , yingyang_list , gongneng_ganyu_edit , fangan_report , fangan_report_edit , shuike , shuike_list , shuike_room , shuike_report , shuike_no_report , shuike_sleep_report } from "../../utils/api";


// 当天需要做的服务剩余量
export function* getRemaining(active) {
    const result = yield axios(`/kfApi/${plan_remaining}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: REMAINING.GET_REMAINING,
            data
        }) 
    }
}

// 获取公司所有专项服务项目列表
export function* getPlanServiceList(active) {
    const result = yield axios(`/kfApi/${plan_service_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        if(active.params){
            let id = 0
            data.map((plan)=>{
                if(plan.service_name=='专家综合评估咨询'){
                    id = plan.id;
                }
            })
            if(id){
                yield put({
                    type: PLAN_SERVICE_LIST.JIN_R_LIST,
                    params:{
                        id:id,
                        ...active.params
                    },
                    data
                }) 
            }
        }else{
            yield put({
                type: PLAN_SERVICE_LIST.GET_PLAN_SERVICE_LIST,
                data
            }) 
        }        
    }
}

// 健康管理师列表
export function* getHealthManagerList(active) {
    const result = yield axios(`/kfApi/${health_manager_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: HEALTH_MANAGER_LIST.GET_HEALTH_MANAGER_LIST,
            data
        }) 
    }
}

// 服务项目列表
export function* postServicePlanDayList(active) {
    const result = yield axios(`/kfApi/${service_plan_day_list}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_DAY_LIST.POST_SERVICE_PLAN_DAY_LIST,
            data
        }) 
    }
}

// 操作服务项目
export function* getServicePlanOperate(active) {
    const result = yield axios(`/kfApi/${plan_operate}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_OPERATE.GET_SERVICE_PLAN_OPERATE,
            data
        }) 
    }
}

// 添加计划(专项)
export function* postServicePlanAdd(active) {
    const result = yield axios(`/kfApi/${plan_add}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_ADD.POST_SERVICE_PLAN_ADD,
            params:{
                id:active.params.user_id,
                card_code:active.params.card_code
            },
            data
        }) 
    }
}

// 理疗服务专项列表
export function* grtServicePlanList(active) {
    const result = yield axios(`/kfApi/${plan_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_LIST.GET_SERVICE_PLAN_LIST,
            data
        }) 
    }
}

// 理疗服务专项结束
export function* grtServicePlanOver(active) {
    const result = yield axios(`/kfApi/${plan_over}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_OVER.GET_SERVICE_PLAN_OVER,
            params:{
                id:active.params.user_id
            },
            data
        }) 
    }
}

// 理疗服务专项取消
export function* grtServicePlanCancel(active) {
    const result = yield axios(`/kfApi/${plan_cancel}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_CANCEL.GET_SERVICE_PLAN_CANCEL,
            params:{
                id:active.params.user_id,
                card_code:active.params.card_code
            },
            data
        }) 
    }
}

// 理疗服务专项详情
export function* grtServicePlanInfo(active) {
    const result = yield axios(`/kfApi/${plan_info}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_INFO.GET_SERVICE_PLAN_INFO,
            data
        }) 
    }
}

// 理疗服务取消排班
export function* grtServicePlanCancelTime(active) {
    const result = yield axios(`/kfApi/${plan_cancel_time}`,active.params);
    if(result.code===1){
        const data = result.data;
        if(active.params.type=="shuike"){
            yield put({
                type: SERVICE_PLAN_CANCEL_TIME.GET_CANCEL_SHUIKE,
                params:{
                    id:data.user_id
                },
                data
            }) 
        }else{
            yield put({
                type: SERVICE_PLAN_CANCEL_TIME.GET_SERVICE_PLAN_CANCEL_TIME,
                params:{
                    id:active.params.service_plan_id
                },
                data
            }) 
        }
    }
}

// 添加计划(专项)
export function* postServicePlanChangeTime(active) {
    const result = yield axios(`/kfApi/${plan_change_time}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SERVICE_PLAN_CHANGE_TIME.POST_SERVICE_PLAN_CHANGE_TIME,
            params:{
                id:active.params.planid
            },
            data
        }) 
    }
}

// 时间段列表
export function* getTimeListTime(active) {
    const result = yield axios(`/kfApi/${time_list_time}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: TIME_LIST_TIME.GET_TIME_LIST_TIME,
            data
        }) 
    }
}

// 获取患者在本次专项服务期间最近的一次理疗报告详情
export function* getLastInfo(active) {
    const result = yield axios(`/kfApi/${last_info}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: LAST_INFO.GET_LAST_INFO,
            data
        }) 
    }else{
        window.location.href=`/diagnose?phone=${active.params.phone}`
    }
}

// 立创接口
export function* getLichuangReport(active) {
    const result = yield axios(`/kfApi/${lichuang_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: LICHUANG_REPORT.GET_LICHUANG_REPORT,
            data
        }) 
    }
}

//鹰眼接口
export function* getYingyanReport(active) {
    const result = yield axios(`/kfApi/${yingyan_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        if(active.params.url){
            window.location.href = data.Doc;
        }else{
            yield put({
                type: YINGYAN_REPORT.GET_YINGYAN_REPORT,
                data
            }) 
        }
        
    }
}

//我方报告
export function* getQuanmaiReport(active) {
    const result = yield axios(`/kfApi/${self_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SEF_REPORT.GET_SEF_REPORT,
            data
        }) 
    }
}

//脉泉报告
export function* getMaiQuanReport(active) {
    const result = yield axios(`/kfApi/${maiquan}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: MAIQUAN_REPORT.GET_MAIQUAN_REPORT,
            data
        }) 
    }
}

// 根据立创报告获取日期列表
export function* getLichuangDate(active) {
    const result = yield axios(`/kfApi/${lichuang_date}`,active.params);
    if(result.code===1 ){
        const data = result.data;
        if(data.length>0){
            const data = result.data;
            yield put({
                type: LICHUANG_DATE.GET_LICHUANG_DATE,
                params:{
                    ...active.params,
                    date:data[0]
                },
                data
            }) 
        }
        else{
            yield put({
                type: LICHUANG_DATE.NO_LICHUANG_DATE,
                data
            }) 
        }
    }
}

// token 获取账户信息 - 微信
export function* postJwtAuth(active) {
    const result = yield axios(`/userapi/${jwt_auth}`,active.params);
    if(result.code===1){
        const data = result.data;
        if(data.id){
            if(active.params.jump == 'server'){
                yield put({
                    type: JWT_AUTH.JWT_PLAN,
                    params:{
                        id:data.id,
                        phone:data.phone
                    },
                    data
                }) 
            }else if(active.params.jump == 'plan'){
                yield put({
                    type: JWT_AUTH.JWT_CURRENT,
                    params:{
                        id:data.id
                    },
                    data
                })
            } else{
                yield put({
                    type: JWT_AUTH.POST_JWT_AUTH,
                    params:{
                        user_id:data.id,
                        phone:data.phone
                    },
                    data
                }) 
            }
            
        }
    }
}

// 获取日期列表(单个的)
export function* getThirParty(active) {
    const result = yield axios(`/kfApi/${third_party_date}`,{
        user_id:active.params.user_id,
        type:active.params.type,
        phone:active.params.phone,
    });
    if(result.code===1){
        const data = result.data;
        if(active.params.go && data.length>0){
            if(active.params.type=='lichuang'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_LICHUANG,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='self'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_SELF,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='yingyan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_YINGYAN,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='maiquan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_MAIQUAN,
                    params:{
                        phone:active.params.phone,
                        user_id:active.params.user_id,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='zhibiao'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_ZHIBIAO,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0]
                    },
                    data
                })
            }
            if(active.params.type=='wenquan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_WENQUAN,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0],
                        fun:active.params.fun
                    },
                    data
                })
            }
            if(active.params.type=='fangan'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_FANGAN,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0],
                        fun:active.params.fun
                    },
                    data
                })
            }
            if(active.params.type=='sleep'){
                yield put({
                    type: THIR_PARTY.THIR_PARTY_SHUIKE,
                    params:{
                        user_id:active.params.user_id,
                        phone:active.params.phone,
                        date:data[0].date,
                        sn:data[0].code,
                        fun:active.params.fun
                    },
                    data
                })
            }
        }else{
            yield put({
                type: THIR_PARTY.GET_THIR_PARTY,
                data
            })
        }
    }
}

// 三方报告汇总
export function* getThirPartyCount(active) {
    const result = yield axios(`/kfApi/${third_party_count}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: THIR_PARTY_COUNT.GET_THIR_PARTY_COUNT,
            data
        }) 
    }
}

// 获取某会员当日所有未完成的排班
export function* getControlList(active) {
    const result = yield axios(`/kfApi/${control_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: CONTRO_LIST.POST_CONTRO_LIST,
            data
        }) 
    }
}

//健康检测指标报告
export function* getZhibiaoReport(active) {
    const result = yield axios(`/kfApi/${zhibiao_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        console.log(data);
        yield put({
            type: ZHIBIAO_REPORT.GET_ZHIBIAO_REPORT,
            data
        }) 
    }
}
//健康检测指标报告创建
export function* postZhibiaoReportCreate(active) {
    const result = yield axios(`/kfApi/${zhibiao_report_create}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: ZHIBIAO_REPORT_CREATE.POST_ZHIBIAO_REPORT_CREATE,
            data
        }) 
    }
}
//健康问券报告
export function* getWenquanReport(active) {
    const result = yield axios(`/kfApi/${wenquan_report}`,{
        user_id:active.params.user_id,
        phone:active.params.phone,
        date:active.params.date,
    });
    if(result.code===1){
        const data = result.data;
        yield put({
            type: WENQUAN_REPORT.GET_WENQUAN_REPORT,
            fun:active.params.fun,
            date:active.params.date,
            data
        }) 
    }
}
//健康问券创建或修改
export function* postWenquanReportEdit(active) {
    const result = yield axios(`/kfApi/${wenquan_report_edit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: WENQUAN_REPORT_EDIT.POST_WENQUAN_REPORT_EDIT,
            data
        }) 
    }
}

//中医调养方法列表
export function* getZhongyiTiaoyangList(active) {
    const result = yield axios(`/kfApi/${zhongyi_tiaoyang_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: ZHONGYI_TIAOYANG_LIST.GET_ZHONGYI_TIAOYANG_LIST,
            data
        }) 
    }
}

//中医调养方法创建或修改
export function* postZhongyiTiaoyangEdit(active) {
    const result = yield axios(`/kfApi/${zhongyi_tiaoyang_edit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: ZHONGYI_TIAOYANG_EDIT.POST_ZHONGYI_TIAOYANG_EDIT,
            data
        }) 
    }
}

//营养补充列表
export function* getYingyangList(active) {
    const result = yield axios(`/kfApi/${yingyang_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: YINGYANG_LIST.GET_YINGYANG_LIST,
            data
        }) 
    }
}

//功能医学干预列表
export function* getGongnengGanyuList(active) {
    const result = yield axios(`/kfApi/${gongneng_ganyu_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: GONGNENG_GANYU_LIST.GET_GONGNENG_GANYU_LIST,
            data
        }) 
    }
}

//功能医学干预创建或修改
export function* postGongnengGanyuEdit(active) {
    const result = yield axios(`/kfApi/${gongneng_ganyu_edit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: GONGNENG_GANYU_EDIT.POST_GONGNENG_GANYU_EDIT,
            data
        }) 
    }
}

//健康评估及健康管理方案报告
export function* getFanganReport(active) {
    const result = yield axios(`/kfApi/${fangan_report}`,{
        user_id:active.params.user_id,
        phone:active.params.phone,
        date:active.params.date,
    });
    if(result.code===1){
        const data = result.data;
        yield put({
            type: FANGAN_REPORT.GET_FANGAN_REPORT,
            fun:active.params.fun,
            data
        }) 
    }
}

//健康评估及健康管理方案创建或修改
export function* postFanganReportEdit(active) {
    const result = yield axios(`/kfApi/${fangan_report_edit}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: FANGAN_REPORT_EDIT.POST_FANGAN_REPORT_EDIT,
            data
        }) 
    }
}

//根据用户id获取睡客服务计划列表
export function* getShuike(active) {
    const result = yield axios(`/kfApi/${shuike}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE.GET_SHUIKE,
            data
        }) 
    }
}

//根据睡眠日期获取床垫列表
export function* getShuikeList(active) {
    const result = yield axios(`/kfApi/${shuike_list}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE_LIST.GET_SHUIKE_LIST,
            data
        }) 
    }
}

//睡客排班日期、房间选择
export function* postShuikeRoom(active) {
    const result = yield axios(`/kfApi/${shuike_room}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE_ROOM.POST_SHUIKE_ROOM,
            data,
            params:{
                id:active.params.planid
            },
        }) 
        
    }
}

//睡客
export function* getShuikeReport(active) {
    const result = yield axios(`/kfApi/${shuike_sleep_report}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE_REPORT.GET_SHUIKE_REPORT,
            data
        })
    }else{
        yield put({
            type: SHUIKE_REPORT.GET_SHUIKE_REPORT,
            data:null
        }) 
    }
}