//当天需要做的服务剩余量
export const REMAINING = {
    REMAINING:'REMAINING',
    GET_REMAINING:'GET_REMAINING'
};
//获取公司所有专项服务项目列表
export const PLAN_SERVICE_LIST = {
    PLAN_SERVICE_LIST:'PLAN_SERVICE_LIST',
    GET_PLAN_SERVICE_LIST:'GET_PLAN_SERVICE_LIST',
    //查今日咨询
    JIN_R_LIST:'JIN_R_LIST'
};
//健康管理师列表
export const HEALTH_MANAGER_LIST = {
    HEALTH_MANAGER_LIST:'HEALTH_MANAGER_LIST',
    GET_HEALTH_MANAGER_LIST:'GET_HEALTH_MANAGER_LIST'
};
//服务项目列表
export const SERVICE_PLAN_DAY_LIST = {
    SERVICE_PLAN_DAY_LIST:'SERVICE_PLAN_DAY_LIST',
    POST_SERVICE_PLAN_DAY_LIST:'POST_SERVICE_PLAN_DAY_LIST'
};
//操作服务项目
export const SERVICE_PLAN_OPERATE = {
    SERVICE_PLAN_OPERATE:'SERVICE_PLAN_OPERATE',
    GET_SERVICE_PLAN_OPERATE:'GET_SERVICE_PLAN_OPERATE'
};

//添加计划(专项)
export const SERVICE_PLAN_ADD = {
    SERVICE_PLAN_ADD:'SERVICE_PLAN_ADD',
    POST_SERVICE_PLAN_ADD:'POST_SERVICE_PLAN_ADD'
};

//理疗服务专项列表
export const SERVICE_PLAN_LIST = {
    SERVICE_PLAN_LIST:'SERVICE_PLAN_LIST',
    GET_SERVICE_PLAN_LIST:'GET_SERVICE_PLAN_LIST'
};

//理疗服务专项结束
export const SERVICE_PLAN_OVER = {
    SERVICE_PLAN_OVER:'SERVICE_PLAN_OVER',
    GET_SERVICE_PLAN_OVER:'GET_SERVICE_PLAN_OVER'
};

//理疗服务专项取消
export const SERVICE_PLAN_CANCEL = {
    SERVICE_PLAN_CANCEL:'SERVICE_PLAN_CANCEL',
    GET_SERVICE_PLAN_CANCEL:'GET_SERVICE_PLAN_CANCEL'
};

//理疗服务专项详情
export const SERVICE_PLAN_INFO = {
    SERVICE_PLAN_INFO:'SERVICE_PLAN_INFO',
    GET_SERVICE_PLAN_INFO:'GET_SERVICE_PLAN_INFO'
};

//理疗服务取消排班
export const SERVICE_PLAN_CANCEL_TIME = {
    SERVICE_PLAN_CANCEL_TIME:'SERVICE_PLAN_CANCEL_TIME',
    GET_SERVICE_PLAN_CANCEL_TIME:'GET_SERVICE_PLAN_CANCEL_TIME',
    GET_CANCEL_SHUIKE:'GET_CANCEL_SHUIKE'
};

//排班日期选择
export const SERVICE_PLAN_CHANGE_TIME = {
    SERVICE_PLAN_CHANGE_TIME:'SERVICE_PLAN_CHANGE_TIME',
    POST_SERVICE_PLAN_CHANGE_TIME:'POST_SERVICE_PLAN_CHANGE_TIME'
};

//时间段列表
export const TIME_LIST_TIME = {
    TIME_LIST_TIME:'TIME_LIST_TIME',
    GET_TIME_LIST_TIME:'GET_TIME_LIST_TIME'
};

//获取患者在本次专项服务期间最近的一次理疗报告详情
export const LAST_INFO = {
    LAST_INFO:'LAST_INFO',
    GET_LAST_INFO:'GET_LAST_INFO'
};
//立创接口
export const LICHUANG_REPORT = {
    LICHUANG_REPORT:'LICHUANG_REPORT',
    GET_LICHUANG_REPORT:'GET_LICHUANG_REPORT'
};
//鹰眼接口
export const YINGYAN_REPORT = {
    YINGYAN_REPORT:'YINGYAN_REPORT',
    GET_YINGYAN_REPORT:'GET_YINGYAN_REPORT'
};
//脉泉接口
export const MAIQUAN_REPORT = {
    MAIQUAN_REPORT:'MAIQUAN_REPORT',
    GET_MAIQUAN_REPORT:'GET_MAIQUAN_REPORT'
};
//我方报告详情
export const SEF_REPORT = {
    SEF_REPORT:'SEF_REPORT',
    GET_SEF_REPORT:'GET_SEF_REPORT'
};
//根据立创报告获取日期列表
export const LICHUANG_DATE = {
    LICHUANG_DATE:'LICHUANG_DATE',
    GET_LICHUANG_DATE:'GET_LICHUANG_DATE',
    NO_LICHUANG_DATE:'NO_LICHUANG_DATE'
};

//修改一级目录
export const change = {
    change:'change'
};
//修改二级目录
export const changeTwo = {
    changeTwo:'changeTwo'
};

//修改 状态
export const MODIFY = "modify";

export const MODIFY_All = "modify_all";

//token 获取账户信息 - 微信
export const JWT_AUTH = {
    JWT_AUTH:'JWT_AUTH',
    POST_JWT_AUTH:'POST_JWT_AUTH',
    JWT_PLAN:'JWT_PLAN',
    JWT_CURRENT:'JWT_CURRENT'
};

//获取日期列表(单个的)
export const THIR_PARTY = {
    THIR_PARTY:'THIR_PARTY',
    GET_THIR_PARTY:'GET_THIR_PARTY',
    THIR_PARTY_LICHUANG:'THIR_PARTY_LICHUANG',
    THIR_PARTY_SELF:'THIR_PARTY_SELF',
    THIR_PARTY_MAIQUAN:'THIR_PARTY_MAIQUAN',
    THIR_PARTY_YINGYAN:'THIR_PARTY_YINGYAN',
    THIR_PARTY_ZHIBIAO:'THIR_PARTY_ZHIBIAO',
    THIR_PARTY_WENQUAN:'THIR_PARTY_WENQUAN',
    THIR_PARTY_FANGAN:'THIR_PARTY_FANGAN',
    THIR_PARTY_SHUIKE:'THIR_PARTY_SHUIKE'
};

//三方报告汇总
export const THIR_PARTY_COUNT = {
    THIR_PARTY_COUNT:'THIR_PARTY_COUNT',
    GET_THIR_PARTY_COUNT:'GET_THIR_PARTY_COUNT'
};

//获取某会员当日所有未完成的排班
export const CONTRO_LIST = {
    CONTRO_LIST:'CONTRO_LIST',
    POST_CONTRO_LIST:'POST_CONTRO_LIST'
};

//健康检测指标报告
export const ZHIBIAO_REPORT = {
    ZHIBIAO_REPORT:'ZHIBIAO_REPORT',
    GET_ZHIBIAO_REPORT:'GET_ZHIBIAO_REPORT'
};

//健康检测指标报告创建
export const ZHIBIAO_REPORT_CREATE = {
    ZHIBIAO_REPORT_CREATE:'ZHIBIAO_REPORT_CREATE',
    POST_ZHIBIAO_REPORT_CREATE:'POST_ZHIBIAO_REPORT_CREATE'
};

//健康问券报告
export const WENQUAN_REPORT = {
    WENQUAN_REPORT:'WENQUAN_REPORT',
    GET_WENQUAN_REPORT:'GET_WENQUAN_REPORT'
};

//健康问券创建或修改
export const WENQUAN_REPORT_EDIT = {
    WENQUAN_REPORT_EDIT:'WENQUAN_REPORT_EDIT',
    POST_WENQUAN_REPORT_EDIT:'POST_WENQUAN_REPORT_EDIT'
};

//中医调养方法列表
export const ZHONGYI_TIAOYANG_LIST = {
    ZHONGYI_TIAOYANG_LIST:'ZHONGYI_TIAOYANG_LIST',
    GET_ZHONGYI_TIAOYANG_LIST:'GET_ZHONGYI_TIAOYANG_LIST'
};

//中医调养方法创建或修改
export const ZHONGYI_TIAOYANG_EDIT = {
    ZHONGYI_TIAOYANG_EDIT:'ZHONGYI_TIAOYANG_EDIT',
    POST_ZHONGYI_TIAOYANG_EDIT:'POST_ZHONGYI_TIAOYANG_EDIT'
};

//功能医学干预列表
export const GONGNENG_GANYU_LIST = {
    GONGNENG_GANYU_LIST:'GONGNENG_GANYU_LIST',
    GET_GONGNENG_GANYU_LIST:'GET_GONGNENG_GANYU_LIST'
};
//功能医学干预创建或修改
export const GONGNENG_GANYU_EDIT = {
    GONGNENG_GANYU_EDIT:'GONGNENG_GANYU_EDIT',
    POST_GONGNENG_GANYU_EDIT:'POST_GONGNENG_GANYU_EDIT'
};

//功能医学干预列表
export const YINGYANG_LIST = {
    YINGYANG_LIST:'YINGYANG_LIST',
    GET_YINGYANG_LIST:'GET_YINGYANG_LIST'
};

//健康评估及健康管理方案报告
export const FANGAN_REPORT = {
    FANGAN_REPORT:'FANGAN_REPORT',
    GET_FANGAN_REPORT:'GET_FANGAN_REPORT'
};
//健康评估及健康管理方案创建或修改
export const FANGAN_REPORT_EDIT = {
    FANGAN_REPORT_EDIT:'FANGAN_REPORT_EDIT',
    POST_FANGAN_REPORT_EDIT:'POST_FANGAN_REPORT_EDIT'
};    

//根据用户id获取睡客服务计划列表
export const SHUIKE = {
    SHUIKE:'SHUIKE',
    GET_SHUIKE:'GET_SHUIKE'
};
//根据睡眠日期获取床垫列表
export const SHUIKE_LIST = {
    SHUIKE_LIST:'SHUIKE_LIST',
    GET_SHUIKE_LIST:'GET_SHUIKE_LIST'
};
//睡客排班日期、房间选择
export const SHUIKE_ROOM = {
    SHUIKE_ROOM:'SHUIKE_ROOM',
    POST_SHUIKE_ROOM:'POST_SHUIKE_ROOM'
};
//睡客
export const SHUIKE_REPORT = {
    SHUIKE_REPORT:'SHUIKE_REPORT',
    GET_SHUIKE_REPORT:'GET_SHUIKE_REPORT'
};