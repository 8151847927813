import {
    SHUIKE_DESCRIPTION,
    SHUIKE_ORGANIZATION_HOME,
    BASE_INFO_FIND,
    SHUIKE_BASE_INFO_ADD,
    SHUIKE_BASE_REPORT,
    SHUIKE_MODIFY
} from './actionTypes'
import { message } from 'antd';
import { yibiao , shuiqian , Kline , pause , area , waterfall } from '../../utils/chart';

// 数据池
const defaultState = {
    homeColumns:[],
    homeName:[],
    homeStale:true,
    homeShow:false,
    company_id:0,
    shuike:[],
    shuikeData:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case SHUIKE_MODIFY:                   //修改状态
            newState[action.params.key] = action.params.value;
            return newState;
        case SHUIKE_DESCRIPTION.GET_SHUIKE_DESCRIPTION:             //设备详情
            return newState;
        case SHUIKE_ORGANIZATION_HOME.GET_SHUIKE_ORGANIZATION_HOME: //根据机构来获取房间号列表
            let homeColumns = [];
            action.data.map((item)=>{
                homeColumns.push({
                    label:item.home,
                    value:JSON.stringify({equipment_code:item.equipment_code,home:item.home})
                });
            })
            newState.homeName = homeColumns;
            newState.homeColumns = homeColumns;
            newState.homeStale = false;
            return newState;
        case BASE_INFO_FIND.POST_BASE_INFO_FIND:                    //查询用户基本信息(用户和房间绑定)
            newState.homeShow = true;
            if(action.data.company_id){
                newState.homeId = action.data.id;
                let data1 = new Date(action.data.start_date);
                let data2 = new Date(action.data.end_date);
                action.data.fun({
                    data1:`${data1.getFullYear()}-${max10(data1.getMonth()+1)}-${max10(data1.getDate())}`,
                    data2:`${data2.getFullYear()}-${max10(data2.getMonth()+1)}-${max10(data2.getDate())}`,
                    data3:{
                        equipment_code:action.data.equipment_code,
                        home:action.data.equipment.home
                    }
                })
            }
            return newState;
        case SHUIKE_BASE_INFO_ADD.POST_SHUIKE_BASE_INFO_ADD:        //用户基本信息填写及修改(用户和房间绑定)
            window.location.href = `/sleepCode?url=${encodeURI(action.data.biz)}​`;
            return newState;
        case SHUIKE_BASE_REPORT.GET_SHUIKE_BASE_REPORT:             //获取报告详情
            if(action.data.report.health_hr){
                action.data.report.health_hr = JSON.parse(action.data.report.health_hr);
            }
            if(action.data.report.health_hrv){
                action.data.report.health_hrv = JSON.parse(action.data.report.health_hrv);
            }
            if(action.data.report.health_rr){
                action.data.report.health_rr = JSON.parse(action.data.report.health_rr);
            }
            if(action.data.report.sleep_duration){
                action.data.report.sleep_duration = JSON.parse(action.data.report.sleep_duration);
            }
            if(action.data.report.sleep_restlessness){
                action.data.report.sleep_restlessness = JSON.parse(action.data.report.sleep_restlessness);
            }
            if(action.data.report.sleep_stages){
                action.data.report.sleep_stages = JSON.parse(action.data.report.sleep_stages);
            }
            if(action.data.report.sleep_routine){
                action.data.report.sleep_routine = JSON.parse(action.data.report.sleep_routine);
            }

            if(action.data.report['breathing-interruption']){
                action.data.report['breathing-interruption'] = JSON.parse(action.data.report['breathing-interruption']);
            }

            if(action.data.report['sleep-score']){
                action.data.report['sleep-score'] = JSON.parse(action.data.report['sleep-score']);
            }
            
            if(action.data.report['health-score']){
                action.data.report['health-score'] = JSON.parse(action.data.report['health-score']);
            }
            
            newState.shuikeData = action.data;
            
            // if(newState.shuikeData.report.sleep_duration){
            //     setTimeout(function(){
            //         yibiao('diagram-1',{
            //             max:9,
            //             min:0,
            //             data:newState.shuikeData.report.sleep_duration.sleepTime,
            //             value:newState.shuikeData.report.sleep_duration.sleepDurationStr
            //         });
            //     },1)
            // }

            if(newState.shuikeData.report.sleep_stages){
                let filterData = filterateFun(newState.shuikeData.report.sleep_stages.list);
                setTimeout(function(){
                    shuiqian('diagram-2',{
                        xAxis:filterData.x,
                        data:filterData.y
                    });
                },1)
            }

            if(newState.shuikeData.report['health_hr']){
                let health_hr = newState.shuikeData.report['health_hr'];
                let list = health_hr.list;
                let x = [];
                let y = [];
                if(list.length>0){
                    list.map((lir)=>{
                        let start = lir.start;
                        x.push(formatSeconds(start));
                        lir.list.map((sit,index)=>{
                            if(index){
                                start = start+60;
                                x.push(formatSeconds(start));
                            }
                        })
                        y = y.concat(lir.list);
                    })
                }
                setTimeout(function(){
                    Kline('diagram-3',{
                        data:x,
                        value:y
                    })
                },1)
            }
            if(newState.shuikeData.report['health_rr']){
                let health_rr = newState.shuikeData.report['health_rr'];
                let list = health_rr.list;
                let x = [];
                let y = [];
                if(list.length>0){
                    list.map((lir)=>{
                        let start = lir.start;
                        x.push(formatSeconds(start));
                        lir.list.map((sit,index)=>{
                            if(index){
                                start = start+60;
                                x.push(formatSeconds(start));
                            }
                        })
                        y = y.concat(lir.list);
                    })
                }
                setTimeout(function(){
                    Kline('diagram-7',{
                        data:x,
                        value:y
                    })
                },1)
            }
            if(newState.shuikeData.report['breathing-interruption']){
                let breathing_interruption = newState.shuikeData.report['breathing-interruption'];
                let min = breathing_interruption.start;
                let max = breathing_interruption.end;
                let list = breathing_interruption.list;
                let x = [];
                if(list.length>0){
                    let diff= parseInt(((max+86400)-min)/list.length);
                    x.push(formatSeconds(min));
                    let f = min;
                    list.map((lir,index)=>{
                        if(index){
                            x.push(formatSeconds(f+diff));
                            f= f+diff;
                        }
                    })
                }
                
                setTimeout(function(){
                    pause('diagram-4',{
                        data:x,
                        value:list
                    });
                },1)
            }

            if(newState.shuikeData.report['health_hrv']){
                let health_hrv = newState.shuikeData.report['health_hrv'];
                let timeList = health_hrv.timeList;
                let list = health_hrv.list;
                let x = [];
                //去除时间区间的最大值和最小值，根据list的个数算出时间
                if(list.length>0){
                    timeList.map((item,index)=>{
                        if(item<43200){
                            timeList[index] = item+86400;
                        }
                    })
                    let max = Math.max(...timeList);
                    let min = Math.min.apply(null,timeList);
                    //等分时间戳
                    let diff= parseInt((max-min)/list.length);
                    x.push(formatSeconds(min));
                    let f = min;
                    list.map((lir,index)=>{
                        if(index){
                            x.push(formatSeconds(f+diff));
                            f= f+diff;
                        }
                    })
                }
                setTimeout(function(){
                    area('diagram-5',{
                        data:x,
                        value:list
                    });
                },1)
            }
            if(newState.shuikeData.report.sleep_restlessness){
                let list = filterateFun(newState.shuikeData.report.sleep_restlessness.list).data;
                let x = [];
                let x1 = [];
                let y = [];
                //删除最开始和最后两个觉醒数据
                if(list.length>0){
                    list[0].type=0;
                    list[list.length-1].type=0;
                    list.map((lir,index)=>{
                        if(lir.type==4||lir.type==5){
                            x.push(1);
                            x1.push(0);
                        }else if( lir.type==0 && index!==0 && index!==list.length-1 ){
                            x.push(0);
                            x1.push(1);
                        }else{
                            x.push(0);
                            x1.push(0);
                        }
                        y.push(lir.start)
                    })
                }
                setTimeout(function(){
                    pause('diagram-6',{
                        data:y,
                        value:x,
                        value1:x1
                    });
                },1)
            }

            // if(newState.shuikeData.report.sleep_routine){
            //     let list = newState.shuikeData.report.sleep_routine.list;
            //     let x = [];
            //     let z = [];
            //     let i = [];
            //     let y = [];
            //     let u = [];

                

            //     list.map((lir,index)=>{
            //         x.push(lir.pointXFlag);
            //         z.push(miaoDate(guilvDate(lir.timeOutOfBed)));
            //         i.push(miaoDate(guilvDate(lir.timeOutOfBed),guilvDate(lir.wakeUpTime),true));
            //         y.push(miaoDate(guilvDate(lir.wakeUpTime),guilvDate(lir.timeToSleep),true));
            //         u.push(miaoDate(guilvDate(lir.timeToSleep),guilvDate(lir.timeToBed),true));
            //     })

            //     setTimeout(function(){
            //         waterfall('diagram-12',{
            //             data:x,     //周几
            //             qic:z,      //起床时间，空
            //             juex: i,    //起床时间 - 觉醒时间,
            //             shuim:y,    //觉醒时间 - 入睡时间
            //             shangc:u    //入睡时间 - 上床时间
            //         });
            //     },1)
            // }
            return newState;
        default:
            return state;
    }
};

export default Reducer;

function filterateFun(lists){
    let x = [];
    let y = [];
    lists.map((list)=>{
        list.start = formatSeconds(list.start);
        list.end = formatSeconds(list.end);
        x.push(list.end);
        let y_reverse = '';
        switch(list.type){
            case 0:
                y_reverse = 0;
                break;
            case 1:
                y_reverse = 4;
                break;
            case 2:
                y_reverse = 2;
                break;
            case 3:
                y_reverse = 3;
                break;
            case 4:
                y_reverse = 1;
                break;
        }
        y.push(y_reverse)
    })
    return {
        x:x,
        y:y,
        data:lists
    };
}

function formatSeconds(value){
    let second = parseInt(value)
    let minute = 0
    let hour = 0
    if (second > 60) {
      minute = parseInt(second / 60)
      second = parseInt(second % 60)
      if (minute > 60) {
        hour = parseInt(minute / 60)
        minute = parseInt(minute % 60)
      }
    }
  
    let result = '';
    if (minute >= 0) {
      result = '' + max10(parseInt(minute) + result)
    }
    if (hour >= 0) {
      result = '' + max10(parseInt(hour>=24?hour-24:hour)) + ':' + result
    }

    return result
}

function max10(date5){
    return date5>=10?date5:`0${date5}`;
}