import {
    USER_LIST,
    CLICKID,
    DISEASE,
    PROPSVALUE,
    SYMPTOM,
    DISTINGUISH,
    SUBMIT,
    PATIENT,
    VISIT,
    KESHI,
    LILIAO_DISEASE,
    LILIAO_SYMPTOMS,
    LILIAO_SUBMIT,
    INFOS,
    NO_PAIBANUSER_LIST
} from './actionTypes'
import { message } from 'antd';

// 数据池
const defaultState = {
    users: [],      //用户列表
    user:null,      //选中
    illness:[],     //疾病列表
    select:[],      //id组
    symptom:[],     //单选病证
    distinguish:{
        loading:false,
        data:[]
    },               //多选病证
    visit:[],
    visit_one:null,
    keshi:[],         //科室,
    keshiIndex:-1,    //科室选择
    sickness:[],      //病名
    symptom_Inter:[], //症状
    user_list:true,
    info:'',
    oss:null,
    active_id:0,
    user_id:0,
    no_paibanuser_list:[]
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case USER_LIST.GET_LIST:          //请求数据
            action.user.Data = action.user.Data?action.user.Data:[];
            if(action.params.customer_name_phone){
                newState.users = action.user.Data;
                newState.user_list = true;
            }else {
                if(action.user.Data){
                    if(action.params.customer_name_phone==''){
                        newState.users =  [];
                    } 
                    newState.users = newState.users.concat(action.user.Data);
                }else{
                    newState.user_list = false;
                }
            }
            return newState;
        case CLICKID.CLICKID:          //选择用户列表
            newState.active_id = action.params.active_id;
            newState.user_id = action.params.id;
            return newState;
        case CLICKID.GET_CLICKID:
            newState.user = action.data;
            return newState;
        case DISEASE.GET_DISEASE_NAME:     //搜索病例
            newState.illness = action.data;
            return newState;
        case PROPSVALUE:                   //修改状态
            if(action.params instanceof Array){
                action.params.map((item)=>{
                    newState[item.key] = item.value;
                })
            }else{
                newState[action.params.key] = action.params.value;
            }
            return newState;
        case SYMPTOM.POST_SYMPTOM:         //根据主诉和病名找症状（单选）
            if(newState.visit_one){
                action.data.map((data)=>{
                    if(data.id===newState.visit_one.symptom.id){
                        data.active = true;
                    }
                })
            }
            newState.symptom = action.data;
            return newState;
        case DISTINGUISH.GET_DISTINGUISH: //根据单个症状id获取辨别症状列表（多选）
            if(newState.visit_one){
                action.data.map((data)=>{
                    newState.visit_one.symptom_list.map((one)=>{
                        if(data.id===one.id){
                            data.active = true;
                        }
                    })
                })
            }
            newState.distinguish = {
                loading:true,
                data:action.data
            };
            document.getElementById("suit").scrollTop=0;
            return newState;
        case SUBMIT.POST_SUBMIT_REPORT:   //提交报告
            sessionStorage.setItem('user',JSON.stringify(newState.user));
            window.location.href=`/report/${action.data.report_token}`;
            return newState;
        case PATIENT.GET_PATIENT_USER:   //新增修改患者
            if(action.data.code===0){
                message.error(action.data.msg);
            }else{
                message.success(action.data.msg);
                setTimeout(function(){
                    window.location.reload();
                },1000);
            }
            return newState;
        case VISIT.GET_VISIT_RECORD:     //就诊记录
            newState.visit = action.data.list;
            return newState;
        case KESHI.GET_KESHI_DATA:       //所有科室
            newState.keshi = action.data;
            return newState;
        case LILIAO_DISEASE.GET_DISEASE_DATA:       //根据科室id获取所有的病名列表(单选)
            newState.sickness = action.data;
            return newState;
        case LILIAO_SYMPTOMS.GET_SYMPTOMS_DATA:       //根据病名获取所有的症状列表(多选)
            newState.symptom_Inter = action.data;
            return newState;
        case LILIAO_SUBMIT.POST_SUBMIT:       //理疗提交报告
            sessionStorage.setItem('user',JSON.stringify(newState.user));
            window.location.href=`/report/${action.data.report_token}`;
            return newState; 
        case NO_PAIBANUSER_LIST.GET_NO_PAIBANUSER_LIST:       //理疗提交报告
            newState.no_paibanuser_list = action.data;
            return newState; 
        case INFOS.GET_INFO:       //用户信息
            if(action.login){
                let data = action.data;
                if(data.Roles && data.Roles.length>0){
                    if(data.Roles[0].mark == 'health_manager'){
                        window.location.href='/'
                    }else if(data.Roles[0].mark == 'mediciner'){
                        window.location.href='/interpret'
                    }else if(data.Roles[0].mark == 'physiotherapist'){
                        window.location.href='/liliao'
                    }else if(data.Roles[0].mark == 'admin'){
                        window.location.href='/'
                    }
                }
            }else{
                newState.info = action.data;
            }
            return newState;
        default:
            return state;
    }
};

export default Reducer;
