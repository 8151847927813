import { YUYUE } from './actionTypes';

// 数据池
const defaultState = {
    list:null,
    is_doctor:null,
    address_list:null
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case YUYUE.POST_YUYUE:     // 预约服务的接口
            if(action.key){
                newState[action.key] = action.data;
            }
            return newState;
        default:
            return state;
    }
}

export default Reducer;