import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import { 
    SHUIKE_DESCRIPTION,
    SHUIKE_ORGANIZATION_HOME,
    BASE_INFO_FIND,
    SHUIKE_BASE_INFO_ADD,
    SHUIKE_BASE_REPORT } from "./actionTypes";
import { shuike_description, shuike_organization_home, shuike_base_info_find, shuike_base_info_add , shuike_base_report } from "../../utils/api";
import { domain , getDomain , getCookie } from "../../utils/index";

// 设备详情
export function* shuikeDescription(active) {
    const result = yield axios(`/kfApi/${shuike_description}`,active.params.data)
    if(result.code===1){
        const data = result.data;
        if(data.testing_type==2){            //先看报告后注册 2
            window.location.href=`/sleeepShuimian/${active.params.data.sn}`;
        }
        else if(data.testing_type==1){      //先注册后看报告 1
            yield put({
                type: SHUIKE_DESCRIPTION.GET_SHUIKE_DESCRIPTION,
                params:{
                    ...active.params
                }
            })
        }
    }
}

// 根据机构来获取房间号列表
export function* shuikeOrganizationHome(active) {
    const result = yield axios(`/kfApi/${shuike_organization_home}`,active.params)
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE_ORGANIZATION_HOME.GET_SHUIKE_ORGANIZATION_HOME,
            data
        })
    }
}

// 查询用户基本信息(用户和房间绑定)
export function* baseInfoFind(active) {
    const result = yield axios(`/kfApi/${shuike_base_info_find}`,active.params.data,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: BASE_INFO_FIND.POST_BASE_INFO_FIND,
            data:{
                ...data,
                fun:active.params.fun
            },
        }) 
    }
}

// 用户基本信息填写及修改(用户和房间绑定)
export function* baseInfoAdd(active) {
    const result = yield axios(`/kfApi/${shuike_base_info_add}`,active.params,'POST')
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SHUIKE_BASE_INFO_ADD.POST_SHUIKE_BASE_INFO_ADD,
            data
        })
    }
}

// 获取报告详情
export function* baseReport(active) {
    const result = yield axios(`/kfApi/${shuike_base_report}`,active.params)
    if(result.code===1){
        const data = result;
        yield put({
            type: SHUIKE_BASE_REPORT.GET_SHUIKE_BASE_REPORT,
            data:data.data
        })
    }else if(result.data.code==401){
        let appid = getDomain();
        localStorage.setItem("isToken",true);
        window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;
    }
}