import axios from "axios";
import { domain , getDomain } from "./index";
import { message } from 'antd';

//请求超时时间
axios.defaults.timeout = 10000;

//post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//配置请求拦截器
axios.interceptors.request.use((config)=>{
    //统一加token
    if(localStorage.token){
        config.headers['Authorization'] = `${localStorage.token}`;
    }
    return config; 
},(error)=>{
    return Promise.error(error);
});

let errors;
//配置响应拦截器
axios.interceptors.response.use((response)=>{
    //状态过滤
    if(response.status === 200){
        if(response.data.code===1 || response.config.url=='/aliyuncs'){
            return Promise.resolve(response.data);
        }else{
            message.error(response.data.msg);
            return Promise.resolve(response.data);
        }        
    }else{
        return Promise.reject(response.data);
    }
},(error)=>{
    if(error.response && error.response.status === 401){
        console.log(error.config.params);
        if((error.config.params && error.config.params.device == 'web')||JSON.parse(error.config.data).device == 'web'){    //微信
            let appid = getDomain();
            localStorage.setItem("isToken",true);
            window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;
        }else{
            //跳转登录
            window.location.href='/login';
        }
        return Promise.reject(error.response.data);
    }else{
        errors = setTimeout(function(){
            clearTimeout(errors);
            message.error('请求失败！');
        },1000);
        return Promise.reject({
            code:0,
            msg:'请求失败！'
        });
    }
})

//请求方法
export default function ajax( url = '' , params = {} , type='GET' , token = false , header ){
    return  new Promise((resolve,reject)=>{
        let promis;
        //判断请求类型
        if(type.toUpperCase()==='GET'){           
            promis = axios({
                url,
                params,
                token,
                header
            })
        }else if(type.toUpperCase()==='POST'){
            promis = axios({
                method:'post',
                url,
                data:params,
                token,
                header
            })
        }
        //处理结果
        promis.then((response)=>{
            resolve(response);
        }).catch((error)=>{
            reject(error);
        })
    })

}