//列表存储
export const USER_LIST = {
    LIST:'USER_LIST',           //存
    GET_LIST:'GET_USER_LIST'    //请求
};
//选择用户列表
export const CLICKID = {
    CLICKID:'CLICKID',
    GET_CLICKID:'GET_CLICKID'
}

//搜索病名
export const DISEASE = {
    DISEASE_NAME:'DISEASE_NAME',
    GET_DISEASE_NAME:'GET_DISEASE_NAME'
};
//修改状态
export const PROPSVALUE = 'PROPSVALUE';

//根据主诉和病名找症状（单选）
export const SYMPTOM = {
    SYMPTOM_LOOK:'SYMPTOM_LOOK',
    POST_SYMPTOM:'POST_SYMPTOM'
};

//根据单个症状id获取辨别症状列表（多选）
export const DISTINGUISH = {
    DISTINGUISH_LOOK:'DISTINGUISH_LOOK',
    GET_DISTINGUISH:'GET_DISTINGUISH'
};

//提交报告
export const SUBMIT = {
    SUBMIT_REPORT:'SUBMIT_REPORT',
    POST_SUBMIT_REPORT:'POST_SUBMIT_REPORT'
};

//新增患者
export const PATIENT = {
    PATIENT_USER:'PATIENT_USER',
    GET_PATIENT_USER:'GET_PATIENT_USER'
};

//就诊记录
export const VISIT = {
    VISIT_RECORD:'VISIT_RECORD',
    GET_VISIT_RECORD:'GET_VISIT_RECORD'
};

//自定义病证
export const DIV = {
    DIV_SUBMIT:'DIV_SUBMIT',
    POST_DIV_SUBMIT:'POST_DIV_SUBMIT'
};

/**
 * 理疗版
 */
//所有科室
export const KESHI = {
    KESHI_DATA:'KESHI_DATA',
    GET_KESHI_DATA:'GET_KESHI_DATA'
};

// 根据科室id获取所有的病名列表(单选)
export const LILIAO_DISEASE = {
    DISEASE_DATA:'DISEASE_DATA',
    GET_DISEASE_DATA:'GET_DISEASE_DATA'
};

// 根据病名获取所有的症状列表(多选)
export const LILIAO_SYMPTOMS = {
    SYMPTOMS_DATA:'SYMPTOMS_DATA',
    GET_SYMPTOMS_DATA:'GET_SYMPTOMS_DATA'
};

//理疗提交报告
export const LILIAO_SUBMIT = {
    SUBMIT:'SUBMIT',
    POST_SUBMIT:'POST_SUBMIT'
};

//用户名称
export const INFOS = {
    INFO:'INFO',
    GET_INFO:'GET_INFO'
};

//未排班
export const NO_PAIBANUSER_LIST = {
    NO_PAIBANUSER_LIST:'NO_PAIBANUSER_LIST',
    GET_NO_PAIBANUSER_LIST:'GET_NO_PAIBANUSER_LIST'
};

